import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Badge, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const propTypes = {
	children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
	constructor(props) {
		super(props);

		const isAccepted = localStorage.getItem("accept_v1") === "true";
		this.state = { isOpen: !isAccepted };
	}
	toggle() {
		this.setState({ isOpen: false });
		localStorage.setItem("accept_v1", "true");
	}

	render() {
		// eslint-disable-next-line
		const { children, ...attributes } = this.props;

		const frontentVersion = `1.0.${process.env.REACT_APP_VERSION}`;
		const backendVersion = this.props.apiKeys
			? this.props.apiKeys.version === frontentVersion
				? "" // Identical versions -> hide
				: "-" + this.props.apiKeys.version // different versions -> show both
			: "-?"; // backend not leaded yet

		const dbsuffix =
			this.props.apiKeys && this.props.apiKeys.environment !== "Production"
				? this.props.apiKeys.environment[0]
				: "";

		return (
			<React.Fragment>
				<Modal isOpen={this.state.isOpen} centered className="gdpr-popup">
					<ModalBody>
						<p
							className="mt-2 mb-0"
							style={{ textAlign: "justify", fontSize: "1.1rem" }}
						>
							We use cookies to enhance your experience, to implement security
							measures, and to analyze site traffic. By using and further navigating
							this website, you indicate your consent.
						</p>
					</ModalBody>
					<ModalFooter className="pt-0">
						<Button
							style={{ fontSize: "1.1rem" }}
							color="primary"
							onClick={() => {
								this.toggle();
							}}
						>
							Accept
						</Button>{" "}
					</ModalFooter>
				</Modal>
				<span>
					<a href="https://www.localcarbonregistry.org">Local Carbon Registry</a> &copy;
					2019-2021 GHG Accounting Services
					{this.props.apiKeys && this.props.apiKeys.environment !== "Production" && (
						<Badge className="ml-2" color="danger">
							Test
						</Badge>
					)}
				</span>
				<span>
					v{frontentVersion}
					{backendVersion} {dbsuffix}
				</span>
			</React.Fragment>
		);
	}
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

function mapStateToProps(state) {
	return {
		apiKeys: state.settings.apiKeys,
	};
}

export default connect(mapStateToProps, {})(DefaultFooter);
