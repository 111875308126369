import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import GoogleMapReact from "google-map-react";
//import Slider from "react-slick";
import { Link } from "react-router-dom";

// import Spinner from "../common/Spinner";
//import Spinner from "../../components/common/Spinner";
import { getPublicProjects } from "../../actions/projectActions";
// import ProjectItem from "../projects/ProjectItem";
//import ProjectItem from "../../components/projects/ProjectItem";
//import FilterItem from "../../components/search/FilterItem";
//import SearchResultItems from "../../components/search/SearchResultItems";
// import ContentBox from "../../components/common/ContentBox";
// import Carousel from "../../components/common/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import Tooltip from "../../components/common/Tooltip";

import {
	Row,
	Col,
	Button,
	Input,
	Container,
	InputGroup,
	InputGroupAddon,
	FormGroup,
	Label,
	NavItem,
	Nav,
	NavLink,
	TabPane,
	TabContent,
} from "reactstrap";
//import { ProgramTypeEnum } from "../../lib/Enums";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ContentBox from "../../components/common/ContentBox";
import ProjectsMap from "../../components/ProjectsMap";
import { ProgramTypeEnum } from "../../lib/Enums";

class Dashboard extends Component {
	constructor() {
		super();
		this.state = {
			filterType: "all",
			searchField: "",
			openMarker: null,
			openTab: "1",
		};

		this.filterClick = this.filterClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	toggle(tab) {
		if (this.state.openTab !== tab) this.setState({ openTab: tab });
	}

	componentDidMount() {
		//this.props.getFeaturedProjects();
		//this.props.getProjects();
		this.props.getPublicProjects();
	}
	filterClick(e) {
		this.setState({ filterType: e.target.getAttribute("value") });
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onSubmit(e) {
		e.preventDefault();
		this.props.getSearchProjects(this.state.filterType, this.state.searchField);
	}

	render() {
		const { loading, publicProjects } = this.props.project;

		const ready = publicProjects && !loading;

		var entities = {};
		if (ready) {
			for (var px in publicProjects) {
				const p = publicProjects[px];

				if (!entities[p.organization]) {
					entities[p.organization] = {
						organizationLogo: p.organizationLogo,
						microsite: p.organizationSite,
						organizationSerial: p.organizationSerial,
						used: false,
					};
				}

				if (p.programType !== ProgramTypeEnum.Inventory) {
					entities[p.organization].used = true;
				}
			}
		}

		const proposedProjects =
			ready &&
			publicProjects.filter(
				(x) => x.projectStatus === "Proposed" && x.visibility === "Public"
			);
		return (
			<React.Fragment>
				<div className="full-width pt-4 pb-4 cover">
					<Container>
						<h2
							style={{
								fontFamily: "Amiko",
								fontStyle: "normal",
								fontWeight: "normal",
								maxWidth: 400,
								textAlign: "center",
								color: "#5D705F",
							}}
							className="my-5 ml-2"
						>
							Show, plan and track <br /> the <b>Climate Action</b> <br />
							in your community
							<br />
							<Button tag={Link} to="/register/" color="primary" className="mt-3">
								Join today
							</Button>
						</h2>
					</Container>
				</div>
				<div
					style={{ backgroundColor: "#75994C", color: "white" }}
					className="full-width pt-5 pb-5"
				>
					<Container>
						{/* <h2
							style={{
								fontFamily: "Amiko",
								fontStyle: "normal",
								fontWeight: "normal"
							}}
						>
							LOCAL CARBON REGISTRY
						</h2> */}
						<div className="ml-1" style={{ maxWidth: 900, textAlign: "justify" }}>
							<p>
								The Local Carbon Registry is the first integrated platform that
								allows you to combine all aspects of local climate action in a
								transparent and effective way.{" "}
							</p>
							<p>
								Set up your GHG inventory on the registry to establish where you
								stand and to showcase your commitment to account for your impact.
								The registry includes a projections feature that allows you to think
								and plan ahead.
							</p>{" "}
							<p>
								You can also establish your GHG reduction project activities and
								list them on the registry to showcase how and why they reduce GHG
								emissions, share knowledge and learn best practices from other
								members of the community.
							</p>
							<p>
								<i>
									<b style={{ fontSize: "150%" }}>
										Plan your actions based on where you are and where you could
										go.
									</b>
								</i>
							</p>
						</div>
					</Container>
				</div>

				<div className="pb-5">
					<ContentBox title="REGISTRY PROJECTS">
						<Row>
							<Col md="12">
								<div>
									<Nav tabs>
										<NavItem>
											<NavLink
												className={classnames({
													active: this.state.openTab === "1",
												})}
												onClick={() => {
													this.toggle("1");
												}}
											>
												<span style={{ marginRight: 4 }}>
													Registry Projects
												</span>

												<Tooltip
													content={
														"The Registry Projects Map show projects that are registered in the Local Carbon Registry. They can be in planning, active or inactive status."
													}
													name="registry"
													style={{ marginLeft: 5 }}
													left={true}
												/>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: this.state.openTab === "2",
												})}
												onClick={() => {
													this.toggle("2");
												}}
											>
												Seeking Funding
												<Tooltip
													content={
														"The Seeking Funding Map show proposed projects that are actively seeking additional funding. Click on a project to review financial plan to become a funder!"
													}
													name="seekingfunding"
													style={{ marginLeft: 0 }}
													left={true}
												/>
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={this.state.openTab}>
										<TabPane tabId="1">
											<ProjectsMap
												projects={
													ready &&
													publicProjects.filter(
														(x) =>
															x.visibility === "Public" &&
															x.projectStatus !== "Proposed"
													)
												}
												fitMap={false}
											/>
										</TabPane>
										<TabPane tabId="2">
											<ProjectsMap
												projects={proposedProjects}
												fitMap={false}
											/>
										</TabPane>
									</TabContent>
								</div>

								{/* <Map
									style={{ border: "1px solid #434343" }}
									className="dashboard-map"
									center={[49.240242, -122.59637]}
									zoom={8}
									minZoom={2}
									maxBoundsViscosity={0.5}
									maxBounds={L.latLngBounds(
										L.latLng(-89.98155760646617, -180),
										L.latLng(89.99346179538875, 180)
									)}
								>
									<TileLayer
										url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
										attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
									/>

									{}

									{LeafletMarkers}
								</Map> */}
							</Col>
							<Col md="12" className="pt-5">
								<FormGroup>
									<Label for="exampleEmail" style={{ fontFamily: "Amiko" }}>
										SEARCH BY OFFSET SERIAL NUMBER:
									</Label>
									<InputGroup>
										<Input
											name="searchField"
											onChange={this.onChange}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													this.props.history.push(
														`/serial/?serial=${this.state.searchField}`
													);
												}
											}}
										/>
										<InputGroupAddon addonType="append">
											<Button
												color="primary"
												tag={Link}
												to={`/serial/?serial=${this.state.searchField}`}
											>
												<FontAwesomeIcon icon={faSearch} />
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</FormGroup>

								<div
									style={{
										textAlign: "center",
										marginTop: "40px",
									}}
								>
									<Button
										color="primary"
										tag={Link}
										to="/projects/"
										className="mb-4"
									>
										Show All Projects
									</Button>
								</div>
							</Col>
						</Row>
					</ContentBox>
				</div>

				<div
					style={{ backgroundColor: "#75994C", color: "white" }}
					className="full-width pt-5 pb-5"
				>
					<Container>
						<h2
							style={{
								fontFamily: "Amiko",
								fontStyle: "normal",
								fontWeight: "normal",
								textTransform: "uppercase",
								maxWidth: 900,
							}}
						>
							The first integrated platform for managing your GHG reduction pathways
						</h2>
						<div className="ml-5" style={{ maxWidth: 900, textAlign: "justify" }}>
							<p>
								The Local Carbon Registry is powerful and informative because it
								allows you to track your progress and plan your GHG reduction
								pathways through your GHG reduction project activities.{" "}
							</p>

							<p>
								You can list projects that you support, including ones that were not
								established by yourself. And best of all, you can offset your
								current GHG emissions from applicable and credible reductions from
								listed projects.
							</p>
						</div>
					</Container>
				</div>
				<div className="">
					{" "}
					<Row>
						<Col md={{ size: 6, offset: 0 }}>
							<img
								src="/inventory.png"
								alt=""
								width="50%"
								className="mx-auto d-block my-4"
							/>
							<h2
								style={{
									fontFamily: "Amiko",
									fontStyle: "normal",
									fontWeight: "normal",
									textAlign: "center",
								}}
							>
								GHG INVENTORY
							</h2>
							<div className="d-flex" style={{ alignItems: "center" }}>
								<p
									className="mx-auto d-inline-block"
									style={{ textAlign: "justify" }}
								>
									Setup and track your GHG inventory over time.
									<br /> Account for your impact as well as your progress.
									<br /> Plan and realize your GHG reduction journey.
								</p>
							</div>
						</Col>
						<Col md={{ size: 6, offset: 0 }} className="d-flex flex-column">
							<img
								src="/projects.png"
								width="50%"
								className="mx-auto d-block my-4"
								alt=""
							/>
							<h2
								style={{
									fontFamily: "Amiko",
									fontStyle: "normal",
									fontWeight: "normal",
									textAlign: "center",
								}}
							>
								PROJECTS
							</h2>
							<div
								className="d-flex justify-content-center"
								style={{ alignItems: "center " }}
							>
								<p
									className="mx-3 d-inline-block"
									style={{ textAlign: "justify", maxWidth: "360px" }}
								>
									Register local emission reduction projects to make real changes
									happen in your community. Showcase your success stories, provide
									comprehensive information to document the achievements and to
									share lessons learned.
								</p>
							</div>
						</Col>
						<Col md="12" className="mt-2">
							<div
								style={{
									textAlign: "center",
									marginTop: "auto",
								}}
								className="mb-5 "
							>
								<Button tag={Link} to="/register/" color="primary" className="mt-3">
									Join today and be part of the solution
								</Button>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md="6"></Col>
						<Col md="6"></Col>
					</Row>
				</div>

				<ContentBox title="All Participating Entities">
					<Row
						style={{
							backgroundColor: "white",
							borderRadius: "5px",
							marginTop: "-15px",
							marginBottom: "-15px",
							paddingTop: "5px",
						}}
					>
						{Object.keys(entities).map((x) => {
							const cont = (
								<figure>
									<img
										src={entities[x].organizationLogo || "/img.png"}
										alt=""
										style={{ maxHeight: 80, maxWidth: 150 }}
										className="dashboard-entity" //mr-2
									/>
									<figcaption className="dashboard-entitycaption">{x}</figcaption>
								</figure>
							);

							return (
								<Col lg="2" md="3" xs="6" key={x}>
									{entities[x].used ? (
										<Link
											to={
												entities[x].microsite > 0
													? `/${x.toLowerCase().replace(/\s/g, "")}`
													: `/${entities[x].organizationSerial}`
											}
										>
											{cont}
										</Link>
									) : (
										cont
									)}
								</Col>
							);
						})}
					</Row>
				</ContentBox>
			</React.Fragment>
		);
	}
}

Dashboard.propTypes = {
	project: PropTypes.object.isRequired,
	dashboard: PropTypes.object,
};

const mapStateToProps = (state) => ({
	project: state.project,
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getPublicProjects })(Dashboard);
