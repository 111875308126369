import React from "react";

import { Card, CardBody, CardGroup } from "reactstrap";
export default props => {
	//const width = props.width ? props.width : 8;

	return (
		// <Row className="justify-content-center pt-4 pb-4">
		// 	<Col md={width} className="sm-p0">
		// 		<CardGroup>
		// 			<Card>
		// 				<CardBody>
		// 					<h1>{props.title}</h1>
		// 					<p className="text-muted">{props.subtitle}</p>

		// 					{props.children}
		// 				</CardBody>
		// 			</Card>
		// 		</CardGroup>
		// 	</Col>
		// </Row>

		<CardGroup>
			<Card style={{ }} className="my-4">
				<CardBody style={{ backgroundColor: "#75994C", color: "white", borderRadius:"0.3rem 0.3rem 0rem 0rem" }} className="pb-1">
					<h2
						style={{
							fontFamily: "Amiko",
							fontStyle: "normal",
							fontWeight: "normal",
							textTransform: "uppercase",
						}}
					>
						{props.title}

					</h2>
					<p  color="white" >{props.subtitle}</p>
				</CardBody>

				<CardBody  style={{ backgroundColor: "#E5E5E5", borderRadius:"0px 0px 0.3rem 0.3rem"  }}> 
			
				{props.children}
				</CardBody>
			</Card>
		</CardGroup>
	);
};
