import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";
import axios from "axios";
import { CORE_API_ROOT } from "../../config/api-config";
import { Link } from "react-router-dom";

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Container
} from "reactstrap";

class BootstrapHeader extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.closeNavbar = this.closeNavbar.bind(this);

		this.state = {
			isOpen: false
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	onLogoutClick(e) {
		e.preventDefault();
		this.closeNavbar();
		this.props.clearCurrentProfile();
		this.props.logoutUser();
	}

	onResetClick(e) {
		e.preventDefault();
		this.closeNavbar();
		this.props.clearCurrentProfile();

		const userId = this.props.auth.user.certserialnumber;

		axios({
			method: "PUT",
			url: `${CORE_API_ROOT}/users/${userId}`,
			data: {
				contactName: "",
				contactTel: "",
				projectTokens: 0
			}
		}).then(res => {
			this.props.clearCurrentProfile();
			this.props.logoutUser();
		});
	}

	closeNavbar() {
		if (this.state.isOpen) {
			this.toggle();
		}
	}

	render() {
		const { isAuthenticated, user } = this.props.auth;

		const adminLinks = (
			<React.Fragment>
				<UncontrolledDropdown nav inNavbar>
					<DropdownToggle nav caret>
						Admin
					</DropdownToggle>
					<DropdownMenu>
						<Link to="/admin/">
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Dashboard</div>
							</DropdownItem>
						</Link>
						<Link to="/admin/requests">
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Requests</div>
							</DropdownItem>
						</Link>
						<Link to="/admin/users">
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Accounts</div>
							</DropdownItem>
						</Link>

						<Link to="/admin/projects">
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Projects</div>
							</DropdownItem>
						</Link>

						{/* <Link to="/login">
							<DropdownItem onClick={this.onResetClick.bind(this)}>
								<div className="p-0 navLink">Reset Myself</div>
							</DropdownItem>
						</Link> */}
						<Link to="/admin/settings">
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Settings</div>
							</DropdownItem>
						</Link>
					</DropdownMenu>
				</UncontrolledDropdown>
			</React.Fragment>
		);

		const authLinks = (
			<React.Fragment>
				{/* <NavItem>
					<NavLink tag={Link} to="/" onClick={this.closeNavbar}>
						Home
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={Link} to="/about" onClick={this.closeNavbar}>
						How It Works
					</NavLink>
				</NavItem> */}

				<UncontrolledDropdown nav inNavbar>
					<DropdownToggle nav caret>
						Projects
					</DropdownToggle>
					<DropdownMenu>
						<Link to="/myprojects">
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Project List</div>
							</DropdownItem>
						</Link>
						<Link to={`/overview/`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Reductions Overview</div>
							</DropdownItem>
						</Link>

						{/* <Link to={`/create-project`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Register New Project</div>
							</DropdownItem>
						</Link>					 */}
						<Link to={`/project/pay`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Pay for Projects</div>
							</DropdownItem>
						</Link>
					</DropdownMenu>
				</UncontrolledDropdown>
				<UncontrolledDropdown nav inNavbar>
					<DropdownToggle nav caret>
						Inventory
					</DropdownToggle>
					<DropdownMenu>
						<Link to={`/inventory/projects`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Inventory List</div>
							</DropdownItem>
						</Link>
						<Link to={`/offsets/projects`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Purchased Offsets</div>
							</DropdownItem>
						</Link>
						<Link to={`/inventory/overview/`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">GHG True-up Overview</div>
							</DropdownItem>
						</Link>
						{/* <Link to={`/inventory/pay`}>
							<DropdownItem onClick={this.closeNavbar}>
								<div className="p-0 navLink">Pay for Inventory</div>
							</DropdownItem>
						</Link> */}
					</DropdownMenu>
				</UncontrolledDropdown>

				{user.isAdmin && adminLinks}
				<NavItem>
					<NavLink tag={Link} to="/faq" onClick={this.closeNavbar}>
						Help
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={Link} to="/profile/edit" onClick={this.closeNavbar}>
						My Account
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						to="/login"
						onClick={this.onLogoutClick.bind(this)}
						id="signout"
					>
						Sign Out
					</NavLink>
				</NavItem>
			</React.Fragment>
		);
		const guestLinks = (
			<React.Fragment>
				{/* <NavItem>
					<NavLink tag={Link} to="/" onClick={this.closeNavbar}>
						Home
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={Link} to="/about" onClick={this.closeNavbar}>
						How It Works
					</NavLink>
				</NavItem> */}
				<NavItem>
					<NavLink tag={Link} to="/howitworks" onClick={this.closeNavbar}>
						How it works
					</NavLink>
				</NavItem>

				<NavItem>
					<NavLink tag={Link} to="/contact" onClick={this.closeNavbar}>
						Contact
					</NavLink>
				</NavItem>

				<NavItem>
					<NavLink tag={Link} to="/register" onClick={this.closeNavbar}>
						Register
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={Link} to="/login" onClick={this.closeNavbar}>
						Sign In
					</NavLink>
				</NavItem>
			</React.Fragment>
		);

		return (
			<div>
				{/* Style copied from app-header */}
				<Navbar
					color="white"
					light
					expand="lg"
					fixed="top"
					className=""
					style={{ borderBottom: "1px solid #c8ced3" }}
				>
					<Container>
						<NavbarBrand tag={Link} to="/">
							<img
								src="/logo2.png"
								alt=""
								width="250rem"
								className="mr-2 ml-2 mt-1"
								//style={{ marginTop: "-5px" }}
							/>
							{/* Local Carbon Registry */}
						</NavbarBrand>
						<NavbarToggler onClick={this.toggle} />
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav className="ml-auto" navbar>
								{isAuthenticated ? authLinks : guestLinks}
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
			</div>
		);
	}
}

BootstrapHeader.proptype = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	apiKeys: state.settings.apiKeys
});

export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(BootstrapHeader);
